// api.js
import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient();

const baseUrl = "https://api.consultvrsebackend.net/api/";
// const baseUrl = "http://127.0.0.1:8000/api/";
// Login function
export async function login(formData) {
  const response = await fetch(baseUrl + "user/token/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    const errorMessage = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data.token;
}

// Fetch user data
export async function fetchUserData() {
  // check if we have token
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }

  const response = await fetch(baseUrl + "user/me/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// fetch all users in the system
export async function fetchAllUsers() {
  const response = await fetch(baseUrl + "user/allusers/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

//delete a user in the system

export async function deleteUser(id) {
  const response = await fetch(baseUrl + `user/adjusted/user/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = "User deleted successfully";
  return data;
}

// add a user in the system
export async function insertUser(formData) {
  const response = await fetch(baseUrl + "user/create/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// get user data by id
export async function getUserById(id) {
  const response = await fetch(baseUrl + `user/adjusted/user/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch user data
export async function patchUser(formData, id) {
  const response = await fetch(baseUrl + `user/adjusted/user/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// Fetch clinic data
export async function fetchClinicData() {
  const response = await fetch(baseUrl + "clinic/allclinics/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// handle clinic delete
export async function deleteClinic(id) {
  const response = await fetch(baseUrl + `clinic/clinics/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = "Clinic deleted successfully";
  return data;
}

// insert clinic
export async function insertClinic(formData) {
  const response = await fetch(baseUrl + "clinic/clinics/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

export async function getClinicByIdProfile({signal, id}) {
  const response = await fetch(baseUrl + `clinic/clinics/${id}/`, {
    signal,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// get clinic by id
export async function getClinicById(id) {
  const response = await fetch(baseUrl + `clinic/clinics/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch clinic request
export async function patchClinic({formData, id}) {
  const response = await fetch(baseUrl + `clinic/clinics/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// get all clinic services
export async function fetchClinicServices() {
  const response = await fetch(baseUrl + "clinic/service/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch clinic service request
export async function patchClinicService(formData, id) {
  const response = await fetch(baseUrl + `clinic/service/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// delete clinic service
export async function deleteClinicService(id) {
  const response = await fetch(baseUrl + `clinic/service/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = "Clinic service deleted successfully";
  return data;
}

// fetch all clinic speciality
export async function fetchClinicSpeciality() {
  const response = await fetch(baseUrl + "clinic/speciality/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch clinic speciality request
export async function patchClinicSpeciality(formData, id) {
  const response = await fetch(baseUrl + `clinic/speciality/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// delete clinic speciality
export async function deleteClinicSpeciality(id) {
  const response = await fetch(baseUrl + `clinic/speciality/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = "Clinic speciality deleted successfully";
  return data;
}

// fetch all clinic prescription
export async function fetchClinicPrescriptions() {
  const response = await fetch(baseUrl + "clinic/prescription/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch clinic prescription request
export async function patchClinicPrescription(formData, id) {
  const response = await fetch(baseUrl + `clinic/prescription/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// delete clinic prescription
export async function deleteClinicPrescription(id) {
  const response = await fetch(baseUrl + `clinic/prescription/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = "Clinic prescription deleted successfully";
  return data;
}

// fetch clinic procedures
export async function fetchClinicProcedures() {
  const response = await fetch(baseUrl + "clinic/procedures/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch clinic procedure request
export async function patchClinicProcedure(formData, id) {
  const response = await fetch(baseUrl + `clinic/procedures/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// delete clinic procedure
export async function deleteClinicProcedure(id) {
  const response = await fetch(baseUrl + `clinic/procedures/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = "Clinic procedure deleted successfully";
  return data;
}

// fetch clinic labs
export async function fetchClinicLabs() {
  const response = await fetch(baseUrl + "clinic/labs/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch clinic lab request
export async function patchClinicLab(formData, id) {
  const response = await fetch(baseUrl + `clinic/labs/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// delete clinic lab
export async function deleteClinicLab(id) {
  const response = await fetch(baseUrl + `clinic/labs/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = "Clinic lab deleted successfully";
  return data;
}

// fetch all consultants
export async function fetchConsultants() {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "consultant/allconsultants/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// fetch all receptionists
export async function fetchReceptionists() {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "receptionist/allreceptionists/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json(); // Parse error response
    error.info = errorData.message; // Assuming the server sends error message under 'message'
    throw error;
  }

  const data = await response.json();
  return data;
}

// delete consultant
export async function deleteConsultant(id) {
  const response = await fetch(baseUrl + `consultant/consultants/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = "Consultant deleted successfully";
  return data;
}

// insert consultant
export async function insertConsultant(formData) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "consultant/consultants/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch consultant
export async function patchConsultant(formData, id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `consultant/consultants/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// get consultant by id
export async function getConsultantById(id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `consultant/consultants/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// delete receptionist
export async function deleteReceptionist(id) {
  const response = await fetch(baseUrl + `receptionist/receptionists/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = "Receptionist deleted successfully";
  return data;
}

// insert receptionist
export async function insertReceptionist(formData) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "receptionist/receptionists/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch receptionist
export async function patchReceptionist(formData, id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `receptionist/receptionists/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// get receptionist by id
export async function getReceptionistById(id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `receptionist/receptionists/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// fetch all nurses
export async function fetchNurses() {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "nurse/allnurses/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// delete nurse
export async function deleteNurse(id) {
  const response = await fetch(baseUrl + `nurse/nurse/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = "Nurse deleted successfully";
  return data;
}

// insert nurse
export async function insertNurse(formData) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "nurse/nurse/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch nurse
export async function patchNurse(formData, id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `nurse/nurse/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// get nurse by id
export async function getNurseById(id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `nurse/nurse/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// fetch all patients
export async function fetchPatients() {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "patient/patients/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// fetch patient by clinicId
export async function fetchPatientsByClinicId(clinicId) {
  const response = await fetch(
    baseUrl + `patient/patients/clinic/${clinicId}/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// insert patient
export async function insertPatient(formData) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "patient/patients/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    const errorMessage = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch patient
export async function patchPatient(formData, id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `patient/patients/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch medical records in patients
// it has token authentication
export async function patchMedicalRecord(formData, id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `patient/medicalrecords/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// get medical records in patients does not take id
// include token authentication
export async function getMedicalRecords() {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "patient/medicalrecords/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// delete medical records in patients
// include token authentication
export async function deleteMedicalRecord(id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `patient/medicalrecords/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = "Medical record deleted successfully";
  return data;
}

// patch triage in patients
// include token authentication
export async function patchTriage(formData, id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `patient/triage/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// delete triage in patients
// include token authentication
export async function deleteTriage(id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `patient/triage/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = "Triage deleted successfully";
  return data;
}

// get triage by id
// include token authentication
export async function getTriageById(id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `patient/triage/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// get patient by id
// has token authentication
export async function getPatientById(id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `patient/patients/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// insert payment
// include token authentication
export async function insertPayment(formData) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "payment/payment/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    error.info = errorData.message;
    throw errorData;
  }

  const data = await response.json();
  return data;
}

// fetch all payments
// does not include token authentication
export async function fetchAllPaymentsAdmin() {
  const response = await fetch(baseUrl + "payment/allpayments/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    errorMessage = errorData.message;
    throw errorMessage;
  }

  const data = await response.json();
  return data;
}

// patch payment
// include token authentication
export async function patchPayment(formData, id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `payment/payment/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    throw errorData;
  }

  const data = await response.json();
  return data;
}

// get payments by clinicId
// does not include token authentication
export async function getPaymentsByClinicId(clinicId) {
  const response = await fetch(
    baseUrl + `payment/payments/clinic/${clinicId}/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    errorMessage = errorData.message;
    throw errorMessage;
  }

  const data = await response.json();
  return data;
}

// get waiting list by clinicId
// does not include token authentication
export async function getWaitingListByClinicId(clinicId) {
  const response = await fetch(
    baseUrl + `waitinglist/waiting/clinic/${clinicId}/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    throw error;
  }

  const data = await response.json();
  return data;
}

// get payment by id
// include token authentication
export async function getPaymentById(id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `payment/payment/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    throw error;
  }

  const data = await response.json();
  return data;
}

// insert in waiting list
// does not include auth token
export async function insertWaitingList(formData) {
  const response = await fetch(baseUrl + "waitinglist/waiting/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    const errorData = await response.json();
    throw errorData;
  }

  const data = await response.json();
  return data;
}

// get patient by code
// has auth token
export async function getPatientByCode(code) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + `patient/patients/codes/${code}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    throw error;
  }

  const data = await response.json();
  return data;
}

// patch waiting list
// does not include auth token
export async function patchWaitingList(formData, id) {
  const response = await fetch(baseUrl + `waitinglist/waiting/${id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    throw error;
  }

  const data = await response.json();
  return data;
}

// add patient file
// include auth token
export async function addPatientFile(formData, id) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(
    baseUrl + `patient/patients/${id}/upload-file/`,
    {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    }
  );

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    error.code = response.status;
    errorData = await response.json();
    errorMessage = errorData.message;
    throw errorMessage;
  }

  const data = await response.json();
  return data;
}

// fetch patients by clinic id and consultant id
// include auth token
export async function fetchPatientsByClinicIdAndConsultantId(
  clinicId,
  consultantId
) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(
    baseUrl + `patient/patients/clinic/consultant/${clinicId}/${consultantId}/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }
  );

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    errorData = await response.json();
    errorMessage = errorData.message;
    throw error;
  }

  const data = await response.json();
  return data;
}

// add appointment
// include auth token
export async function addAppointment(formData) {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "appointment/appointment/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    errorData = await response.json();
    errorMessage = errorData.message;
    throw errorMessage;
  }

  const data = await response.json();
  return data;
}

// get appointment by clinic id
// does not include auth token
export async function getAppointmentByClinicId(clinicId) {
  const response = await fetch(
    baseUrl + `appointment/appointment/clinic/${clinicId}/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    errorData = await response.json();
    errorMessage = errorData.message;
    throw errorMessage;
  }

  const data = await response.json();
  return data;
}

// get all appointments
// does not include auth token
export async function getAllAppointments() {
  const response = await fetch(baseUrl + "appointment/allappointments/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    errorData = await response.json();
    errorMessage = errorData.message;
    throw errorMessage;
  }

  const data = await response.json();
  return data;
}

// get user
// include auth token
export async function getUser() {
  const token = localStorage.getItem("Token");
  if (!token) {
    throw new Error("Token not found");
  }
  const response = await fetch(baseUrl + "user/me/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error("Sorry, something went wrong!");
    errorData = await response.json();
    errorMessage = errorData.message;
    throw errorData;
  }

  const data = await response.json();
  return data;
}

export async function getTheFetchedClinicDetails() {
  const userData = await fetchUserData();
  const consultants = await fetchConsultants();
  const receptionists = await fetchReceptionists();
  const nurses = await fetchNurses();
  let clinicId = 1;
  let clinicData = await getClinicById(clinicId);
  if (consultants) {
    for (let i = 0; i < consultants.length; i++) {
      if (consultants[i].user === userData.id) {
        clinicId = consultants[i].clinic_id;
        clinicData = await getClinicById(clinicId);
        break;
      }
    }
  }
  if (receptionists) {
    for (let i = 0; i < receptionists.length; i++) {
      if (receptionists[i].user === userData.id) {
        clinicId = receptionists[i].clinic_id;
        clinicData = await getClinicById(clinicId);
        break;
      }
    }
  }
  if (nurses) {
    for (let i = 0; i < nurses.length; i++) {
      if (nurses[i].user === userData.id) {
        clinicId = nurses[i].clinic_id;
        clinicData = await getClinicById(clinicId);
        break;
      }
    }
  }
  return clinicData;
}
