export function getAuthToken() {
  const token = localStorage.getItem("Token");
  return token;
}

export function tokenLoader() {
  return getAuthToken();
}

export function removeAuthToken(){
  localStorage.removeItem("Token");
}
